<template>
  <div class="about" data-aos="fade-up" data-aos-duration="1000">
    <b-container>
      <h1>{{ $t("message.about.hello") }}</h1>
      <p>{{ $t("message.about.text") }}</p>
      <br>
      <div>
        <b-carousel
          id="carousel-about"
          v-model="slide"
          :interval="7000"
          controls
          indicators
          background="#fff"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333"
        >
          <b-carousel-slide
            :img-src="getImgUrl(value.img)"
            v-for="(value, key) in image"
            :key="key"
          >
            <h2>{{ $t("message.about.slide." + value.id) }}</h2>
          </b-carousel-slide>
        </b-carousel>

        <p class="mt-4"></p>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/about/", false, /\.jpeg$/);
      return images("./" + pet + ".jpeg");
    },
  },
  data() {
    return {
      slide: 0,
      image: [
        {
          id: 1,
          img: "fnm-betim-kart",
        },
        {
          id: 2,
          img: "fnm-betim-churras",
        },
        {
          id: 3,
          img: "fnm-vespasiano-kart",
        },
        {
          id: 4,
          img: "fnm-vespasiano-kart-podium",
        },
        {
          id: 5,
          img: "fnm-vespasiano-churras",
        },
        {
          id: 6,
          img: "fnm-sp-kart",
        },
        {
          id: 7,
          img: "fnm-sp-f1",
        },
        {
          id: 8,
          img: "fnm-sp-f1-drs",
        },
      ],
    };
  },
};
</script>

<style scoped>
h1 {
  font-family: fantasy;
}

b-carousel-slide {
  width: 80%;
}
</style>
